import React from 'react';
import { Link } from 'gatsby';


import logoIcon from '../images/logo.png';
import smallLogo from '../images/logo-rwd-normal.png';
import smallLogoWhite from '../images/logo-rwd.png';
import faceWhite from '../images/faceWhite.png';
import styled from 'styled-components';

const TitleLink = styled.a`
  border: none;
  &:hover {
    color: #0000ff;
    text-decoration:none;
  }
  @media(max-width: 1024px) {

  }
`;

const activeClassName = 'active';

const StyledNav = styled.nav`
display: flex;
justify-content: flex-end;
@media(max-width: 1024px) {
  background: rgba(4,29,71,0.8);;
  }
`;

const NavigationWrapper = styled.div`
  width: 60%;
  @media(max-width: 1024px) {
   
    width: 100%;
    }
`;

const StyledList = styled.ul`
  margin-top: 2rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const ListElement = styled.li`
  display: inline-block;
  position: relative;
  padding: 0.5rem 2rem;
  text-align: center;
  font-size: 1.8rem;
  &:last-child{
    padding-right: 0;
    &:after{
      display: none;
    }
  }
  &:after{
    content: "";
    display: block;
    position: absolute;
    top: 15%;
    right: 0;
    width: 2px;
    height: 80%;
    background: #041d47;
  }
  & a {
    color: #041d47; 
    @media(max-width: 1024px){
      color: white;
    }
    &:hover{
      color: #041d47;
      opacity: 0.8;
      @media(max-width: 1024px){
        color: white;
      }
    }
  }
`;

const NavItem = styled(Link).attrs({
  activeClassName
})`
  color: black;
  border: none;

  &:hover {
    color: #041d47;
  }


  &.${activeClassName} {
    font-weight: bold;
  }
`;

const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 1rem;
    @media(max-width: 1024px){
      padding-bottom: 1rem !important;
    }
    `;


class Nav extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      isOpen: false
    }
  }

  render(){
  return (
    <MenuWrapper className={`white-bg main-wrapper d-flex ${this.state.isOpen ? 'change-color' : ''}`}>
      <TitleLink className="xs-my-1" href="/">
        <img className="xs-hidden" alt="autopomocni" src={logoIcon}></img>
        <img className={`xs-only ${this.state.isOpen ? 'hidden' : ''}`} alt="autopomocni" src={smallLogo}></img>
        <img className={`xs-only ${this.state.isOpen ? '' : 'hidden'}`} alt="autopomocni" src={smallLogoWhite}></img>
      </TitleLink>
      <div className={`toggler nav-icon xs-only ${this.state.isOpen ? 'open' : ''}`} onClick={() => {this.setState({isOpen: !this.state.isOpen})}}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
    </div>

      <NavigationWrapper className={`xs-hide-menu ${this.state.isOpen ? 'show-mobile' : ''}`}>
        <div className="d-flex justify-content-end ">
          <a className="phone_link xs-hidden" href="tel:+48570757300">tel. 570 757 300</a>
        </div>
        <div className="line__box xs-hidden">
          <div className="line__dashed line__dashed--black"></div>
        </div>

        <StyledNav className="xs-w-100">
          <StyledList className="xs-w-100 xs-flex-column xs-mt-0">
            <ListElement className="xs-px-0 xs-py-1">
              <NavItem to="/service/" activeClassName={activeClassName}>
                Usługa
          </NavItem>
            </ListElement>
            <ListElement className="xs-px-0 xs-py-1">
              <NavItem to="/replacementCar/" activeClassName={activeClassName}>
                Auto Zastępcze
          </NavItem>
            </ListElement>
            <ListElement className="xs-px-0 xs-py-1">
              <NavItem to="/blog/" activeClassName={activeClassName}>
                Blog
          </NavItem>
            </ListElement>

            <ListElement className="xs-px-0 xs-py-1">
              <NavItem to="/contact/" activeClassName={activeClassName}>
                Kontakt
              </NavItem>
            </ListElement>
            <ListElement className="xs-only xs-px-0 xs-py-1 justify-content-center">
            <a className="mr-1" href="https://www.facebook.com/AutoPomOCni/">
                                    <img alt="" src={faceWhite}></img>
                                </a>
            </ListElement>
          </StyledList>
        </StyledNav>
      </NavigationWrapper>
    </MenuWrapper>
  );
}
};

export default Nav;
