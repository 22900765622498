import React from "react";
import styled from 'styled-components';
import icon from '../images/phone.png';

const QuickPhoneWrapper = styled.div`
    position: fixed;
    bottom: 21px;
    right: 21px;
    background: #80b63f;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 1000;
    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        & img{
            position: relative;
            display: flex;
            width: 50%;
        }
    }
`;

const QuickPhone = () => {
    return (
        <QuickPhoneWrapper>
        <a href="tel:+48570757300">
            <img alt="" src={icon}></img>
        </a>
        </QuickPhoneWrapper>
    );
};

export default QuickPhone;