
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html, body {
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }


  body {
    font-size: 1.8rem;
    font-family: 'Nunito', sans-serif;
    word-break: break-word;
    margin: 0;
  
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0;
  }

  h1{
    font-size: 2em;
    @media(max-width: 1450px){
      font-size: 1.8em;
    }
    @media(max-width: 1100px){
      font-size: 1.6em;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  .blue-gradient-wrapper {
    background: rgb(0,110,164);
    background: linear-gradient(270deg, rgba(0,110,164,1) 0%, rgba(4,29,71,1) 100%);
  }

  .text-default{
    margin: 0;
  }
  .h-100{
    height: 100%;
  }
  .hidden{
    display: none !important;
  }
  .green-color {
    color: #80b63f;
  }
  .main-color-background{
    background: #041d47;
  }
  .main-color{
    color: #041d47;
  }

  .white-color {
    color: #ffffff;
  }

.dark-bg{
  background: #010a18;
}

  .green-bg{
    background: #80b63f;
  }

  .white-bg{
    background: #ffffff;
  }
  .background-blue-gradient{
    background: rgb(4,34,78);
background: linear-gradient(90deg, rgba(4,34,78,1) 0%, rgba(0,105,171,1) 100%);
  }
  .c-input{
    padding: 0.3rem 0.5rem;
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

.btn{
  font-size: 1.8rem;
  outline: none;
  border: none;
  padding: 1rem 5rem;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #80b63f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fixed-size{
  width: 350px;
}

.btn-rounded{
  border-radius: 2rem;
}

.row{
  margin: 0 -2rem;
}

.col-3{
  width: 33%;
  padding: 0 2rem;
}

.text-uppercase{
  text-transform: uppercase;
}

.m-auto{
  margin-left: auto;
  margin-right: auto;
}

.mr-1{
  margin-right: 1rem;
}
.mr-4{
  margin-right: 4rem;
}
.mt-8{
  margin-top: 8rem; 
}

.mt-4{
  margin-top: 4rem;
}
.mt-2{
  margin-top: 2rem;
}

.mt-02{
  margin-top: 0.2rem;
}

.mt-0{
  margin-top: 0;
}

.my-0{
  margin-top: 0;
  margin-bottom: 0;
}
.my-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mb-02{
  margin-bottom: 0.2rem;
}
.mb-05{
  margin-bottom:0.5rem;
}
.mb-8 {
  margin-bottom: 8rem;
}

.ml-1{
  margin-left: 1rem;
}

.mr-1{
  margin-right: 1rem;
}

.ml-2{
  margin-left: 2rem;
}
.ml-4{
  margin-left: 4rem;
}
.mr-2{
  margin-right: 2rem;
}

.mb-4{
  margin-bottom: 4rem;
}
.mb-3{
  margin-bottom: 3rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.mb-1{
  margin-bottom: 1rem;
}
.mb-0{
  margin-bottom: 0 !important;
}

.border {
  border: 1px solid red;
}

.pr-1{
  padding-right: 1rem;
}

.pr-2{
  padding-right: 2rem;
}
.pl-1{
  padding-left: 1rem;
}
.pl-2{
  padding-left: 2rem;
}
.pr-4{
  padding-right: 4rem;
}

.pl-4{
  padding-left: 4rem;
}
.px-2{
  padding-right: 2rem;
  padding-left: 2rem;
}
.px-8{
  padding-right: 8rem;
  padding-left: 8rem;
}
.px-12{
  padding-right: 12rem;
  padding-left: 12rem;
}
.py-05{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-1{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-4{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-8{
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-6{
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.footer-map{
  flex-direction: row !important;
}

.footer-map li:after{
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background: #041d47;
  position: absolute;
  top: 0;
  right: 0;
}

.footer-map li:last-child:after{
  display: none;
}

  .phone_link{
    color: #041d47;
  font-size: 2rem;
  font-weight:600;
  margin-bottom: 1.5rem;
  }
  
.img-wrapper{
  width: 100%;
  position: relative;
}

.img-wrapper img{
  width: 100%;
  z-index: 1;
}


.form-style{
  input, textarea {
    border: 1px solid #041d47;
    font-size: 1.5rem;
    padding: 0.8rem;
  }
  input[type="submit"]{
    border-color: transparent;
  }

}
::-webkit-input-placeholder {
   text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}

:-ms-input-placeholder {  
   text-align: center; 
}

.flex-grow{
  flex-grow:1;
}

  .d-flex{
    display: flex !important;
  }
  .flex-row{
    flex-direction: row;
    @media(max-width: 1024px){
      flex-direction: column;
    }
  }

  .flex-column{
    flex-direction: column; 
  }

  .flex-wrap{
    flex-wrap: wrap;
  }
  .w-100{
    width: 100%;
  }

  .w-75{
    width: 75%;
  }

  .w-60{
    width: 60%;
  }

  .w-50{
    width: 50%;
  }
  .w-30{
    width: 30%;
  }
  .w-40{
    width:40%;
  }
  .w-25{
    width: 25%;
  }

  .w-65 {
    width: 65%;
  }

  .w-35{
    width: 35%;
  }

  .w-10{
    width: 10%;
  }

  .min-height{
    min-height: 1px;
  }
  .main-wrapper{
    display: block;
    position: relative;
    padding: 0 9%;
    @media(max-width: 1600px){
      padding: 0 5%;
    }
    @media(max-width: 1450px){
      padding: 0 2%;
    }
    @media(max-width: 1024px){
      padding: 0 9%;
    }
  }
  .main-wrapper-padding {
    padding: 0 9%;
  }

  .static-height-header{
    height: 710px;
  }

  .content-from-server{
    p {
      img{
        width: auto;
      }
    }
  }

  .header-section{
    padding-top: 8rem;
    z-index: 100;
    top: 0;
    height: 100%;
  }

  .solid-line{
    height: 1px;
  }

  .main-padding-left {
    padding-left: 9%;
  }

  .main-padding-rigth {
    padding-right: 9%;
  }

  .half-main-padding-left{
    padding-left: 4.5%;
  }

  .half-main-padding-right{
    padding-right: 4.5%;
  }

  .quarter-main-padding-left {
    padding-left: 2.25%;
  }

  .quarter-main-padding-right {
    padding-right: 2.25%;
  }

  .position-relative{
    position: relative;
  }

  .position-absolut{
    position: absolute;
  }

  .justify-content-center{
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center{
    align-items: center;
  }

  .align-items-top{
    align-items: flex-start;
  }

  .align-items-end{
    align-items: flex-end;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .main-container{
    padding: 0 5rem;
  }

  .text-align-center{
    text-align: center;
  }

  .box-icon .icon {
    width: 100%;
    text-align: center;
  }

  .box-icon .icon img {
    display: block;
    width: auto;
    margin: 0 auto;
  }

  .icon-desc{
    font-size: 1.9rem;
    line-height: 1.5;
    text-align: center;
  }

  .carousel {
    position: relative;       
    transform-style: preserve-3d;
  }
  
  .carousel__slide {
    opacity: 0;
  position: absolute;
  top:0;
  width: 100%;
  margin: auto;
  z-index: 100;
  transition: transform .5s, opacity .5s, z-index .5s;
  }
  .carousel__slide--active {
    opacity: 1;
    position: relative;
    z-index: 900;
  }
  
  .carousel__arrow {
    position: absolute;
    top:50%;
    width: 3.5rem;
    height: 3.5rem;
    transform: translateY(-50%);
    cursor: pointer; 
    z-index: 1001; 
  }
  .carousel__arrow:focus {
    outline: 0;
  }
  .carousel__arrow:hover {
    opacity: .5;
    outline: none;
    border: none;
  }
  .carousel__arrow--left {
    left: 2%;
  }
  .carousel__arrow--right {
    right: 2%;
  }
  
.carousel__arrow--left:after,
.carousel__arrow--right:after {
  content: " ";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  left: 54%;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: translate(-50%, -50%) rotate(135deg);
}

.carousel_title_box {
  position: absolute;
  min-width: 50%;
  left: 0;
  background: rgba(2,15,31, 0.7);
  padding: 1rem 4rem 1em 9%;
}

.carousel_title_box--upper {
  top: 5rem;
  @media(max-width: 1450px){
    top: 2rem;
  }
}

.carousel_title_box--bottom {
  bottom: 5rem;
  padding-bottom: 2.5em;
  @media(max-width: 1450px){
    bottom: 2rem;
  }
}

.line__box{
  position: relative;
  width: 100%;
}

.line__dashed {
  position: absolute;
  height: 3px;
  left:0;
  width: 100%;
}

.line__dashed--white{
  background-image: linear-gradient(to right, #ffffff 50%, transparent 25%);
    background-size: 100px 100%;
}

.line__dashed--black{
  background-image: linear-gradient(to right, #041d47 50%, transparent 25%);
    background-size: 100px 100%;
}

.line__dashed--green{
  background-image: linear-gradient(to right, #80b63f 50%, transparent 25%);
    background-size: 100px 100%;
}

.line__dashed--bottom {
  bottom: 0;
}

.carousel-slide__title{
  color: white;
  font-size: 2.4rem;
  @media(max-width: 1450px){
    font-size: 2rem;
  }
}

.carousel__image {
  width: 100%;
  display: block;
  position: relative;
  @media(max-width: 1024px){
    width: auto;
    max-height: 40vh;
  }
}

.carousel__arrow--right:after {
  left: 47%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

  .carousel__indicators {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 20px;
  }
  .carousel__indicators li:nth-of-type(n + 2) {
    margin-left: 9px;
  }
  
  .carousel__indicator {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #111;
    cursor: pointer;
    opacity: .15;
    -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
    transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  }
  .carousel__indicator:hover {
    opacity: .5;
  }
  .carousel__indicator--active, .carousel__indicator--active:hover {
    opacity: .75;
  }
  
  .d-flex-inline{
    display: inline-flex;
  }

  .top-position{
    top: 0;
  }

  .mask-bg{
    background: rgba(4,29,71, 0.8);
  }

  .img-mask{
    background: rgba(4,29,71, 0.8);
    height: 100%;
    top: 0;
    z-index: 2;
  }

  .lh-18{
    line-height: 1.8rem;
  }

  .fs-2{
    font-size: 2rem;
  }

  .font-bold{
    font-weight: bold;
  }
  .font-normal{
    font-weight: normal;
  }
  .category-item{
    padding: 0.6rem 1rem;
  }

  .recent-box{
    margin: 0 2rem;
  }

  blockquote{
    background: rgb(133,178,31);
    background: linear-gradient(90deg, rgba(133,178,31,1) 0%, rgba(193,200,33,1) 100%);
    padding: 4.25rem 2.25rem;
    margin: 2rem 0;
    font-weight: bold;
    color: #041d47;
    font-size: 3.0625rem;
    text-align: center;
    p{
      margin: 0;
      font-size: inherit;
      padding: 0;
    }
    p:before,
    p:after{
      display: inline-block;
      font-size: inherit;
      color: inherit;
    }
    p:before{
      margin-right: 0.5rem
      content: '\„';
    }
    p:after{
      margin-left: 0.5rem;
      content: '\”';
    }
  }
  
  figure {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }


  p {
    line-height: 1.5em;
    font-size: 1.8rem;
    @media(max-width: 1450px){
      font-size: 1.6rem;
    }
    @media(max-width: 1100px){
      font-size: 1.4rem;
    }
  }

  .xs-only{
    display: none !important;
    @media(max-width: 1024px){
      display: flex !important;
    }
  }

  .main-pattern-bg{
    background: rgb(3,110,164);
background: linear-gradient(90deg, rgba(3,110,164,1) 0%, rgba(129,176,34,1) 100%);
  }

  .type-car-box{
    left: 0;
    bottom: 0;
  }


  //media queries

  @media(max-width: 1024px){
    .xs-fs-15{
      font-size: 1.5rem;
    }
    .change-color{
      background: #041d47;
    }
    .nav-icon{
      width: 45px;
      height: 35px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        background: #041d47;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
    }

    .nav-icon span:nth-child(1) {
      top: 0px;
    }
    
    .nav-icon span:nth-child(2), .nav-icon span:nth-child(3) {
      top: 12px;
    }
    
    .nav-icon span:nth-child(4) {
      top: 24px;
    }
    
    .nav-icon.open span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
    
    .nav-icon.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background: white;
    }
    
    .nav-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      background: white;
    }
    
    .nav-icon.open span:nth-child(4) {
      top: 24px;
      width: 0%;
      left: 50%;
    }

    .w-50, .w-25, .col-3, .w-60, .w-65, .w-35{
      width: 100%;
    }
    .xs-w-35{
      width: 35% !important;
    }
    .xs-hidden{
      display: none !important;
    }
   
    .xs-text-center{
      text-align: center;
    }

    .xs-flex-row{
      flex-direction: row !important;
    }

    .xs-main-wrapper{
      padding: 0 9%;
    }
    .xs-text-center{
      text-align: center;
    }

    .xs-flex-row{
      flex-direction: row !important;
    }

    .xs-main-wrapper{
      padding: 0 9%;
    }
    .xs-w-100{
      width: 100% !important;
    }
    .mb-xs-4{
      margin-bottom: 4rem;
    }
    .mb-xs-2{
      margin-bottom: 2rem;
    }
    .xs-mt-0{
      margin-top: 0 !important;
    }
    .xs-overflow-hidden{
      overflow:hidden;
    }
    
    .xs-width-auto{
      width: auto !important;
    }
    .xs-fs-14{
      font-size: 1.4rem;
    }
    .xs-ml-2{
      margin-left: 2rem;
    }
    .xs-mt-1{
      margin-top: 1rem;
    }
    .xs-pr-0 {
      padding-right: 0 !important;
    }
    .xs-justify-content-start{
      justify-content: flex-start !important;
    }
    .xs-justify-content-between{
      justify-content: space-between;
    }
    .xs-px-0 {
      padding-left:0 !important;
      padding-right:0 !important;
    }
    .xs-mb-2 {
      margin-bottom: 2rem;
    }
    .xs-py-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .xs-pl-0{
      padding-left: 0!important;
    }
    .xs-pl-2{
      padding-left: 2rem;
    }
    .xs-px-2 {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .xs-pr-2{
      padding-right: 2rem;
    }
    .xs-my-1{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .xs-inverse{
      flex-direction: column-reverse;
    }
    .xs-mr-0{
      margin-right: 0;
    }
    .xs-mr-2{
      margin-right: 2rem;
    }
    .xs-ml-0{
      margin-left: 0;
    }
    .pl-xs-0{
      padding-left: 0 !important;
    }
    .xs-m-auto{
      margin: 0 auto;
    }
    .xs-flex-column{
      flex-flow: column
    }
    .xs-main-padding{
      padding-left: 9%;
      padding-right: 9%;
    }
    .quarter-main-padding-right{
      padding-right: 9%;
    }
    .xs-justify-content-center{
      justify-content: center;
    }
    .d-xs-none {
      display: none;
    }
    .xs-hide-menu {
      display: none;
    }
    .xs-hide-menu.show-mobile{
      display: flex;
      position: absolute;
      width: 100%;
      left: 0;
      top: 80px;
  }

  .xs-only.hidden{
    display:none !important;
  }


`;

export default GlobalStyle;
