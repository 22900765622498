import React from "react";
import logoIcon from '../images/logo.png';
import facebook from '../images/face.png';
import yt from '../images/yt.png';
import Newsletter from "./Newsletter";


class Footer extends React.Component {

    render(){
        return(
            <>
            <div className="mb-8 mt-8 d-flex justify-content-center">
                <div className="w-50">
                    <div className="line__box d-flex">
                        <div className="line__dashed line__dashed--green"></div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row xs-inverse">
                    <div className="d-flex col-3">
                        <div className="d-flex justify-content-center flex-column w-100">
                            <div className="justify-content-center d-flex mb-1">
                                <img alt="" src={logoIcon}></img>
                            </div>
                            <div className="d-flex justify-content-center mb-1">
                               <span className="mr-1">tel. </span> <a href="tel:+48570757300">570 757 300</a>
                            </div>
                            <div className="d-flex justify-content-center mb-1">
                                <a href="mailto:info@autopomocni.pl">info@autopomocni.pl</a>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a className="mr-1" href="https://www.facebook.com/AutoPomOCni/">
                                    <img alt="" src={facebook}></img>
                                </a>
                                <a>
                                    <img alt="" src={yt}></img>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  flex-column col-3 mb-xs-4">
                        {/* <h2 className="green-color text-align-center">Najczęściej zadawane pytania</h2>
                        <ul>
                            <li className="green-color text-align-center">pytanie 1 </li>
                            <li className="green-color text-align-center">pytanie 2 </li>
                            <li className="green-color text-align-center">pytanie 3 </li>
                            <li className="green-color text-align-center">pytanie 4 </li>
                        </ul> */}
                    </div>
                    <div className="d-flex col-3 mb-xs-4">
                        <Newsletter></Newsletter>
                    </div>
                </div>
                <div className="justify-content-center w-100 d-flex mt-8 mb-8">
                    <div className="d-flex ">
                        <ul className="footer-map d-flex flex-row flex-wrap">
                            <li className="mr-1 pr-1 position-relative"><a href="/service/">Usługa</a></li>
                            <li className="mr-1 pr-1 position-relative"><a href="/replacementCar/">Auto Zastępcze</a></li>
                            <li className="mr-1 pr-1 position-relative"><a href="/blog/">Blog</a></li>
                            <li className="mr-1 pr-1 position-relative"><a href="/contact/">Kontakt</a></li>
                            <li className="mr-1 pr-1 position-relative"><a>Polityka prywatności</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Footer;