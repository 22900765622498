import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import 'prismjs/themes/prism.css';
import QuickPhone from '../components/QuickPhone';
import Nav from './../components/Nav';
import Favicon from './../components/Favicon';
import Footer from '../components/Footer';

import GlobalStyle from './GlobalStyle';

const Main = styled.main`
padding-top: 141px;
@media(max-width: 1024px){
  padding-top: 90px;
}
`;

const StyledHeader = styled.header`
position: fixed;
top: 0;
padding: 0;
z-index:1000;
`;


const Layout = ({ children, hideFooter }) => {
  
  let footer;
  if(hideFooter){
    footer =   <div className="justify-content-center w-100 d-flex mt-8 mb-8">
    <div className="d-flex ">
        <ul className="footer-map d-flex flex-row flex-wrap">
            <li className="mr-1 pr-1 position-relative"><a href="/service/">Usługa</a></li>
            <li className="mr-1 pr-1 position-relative"><a href="/replacementCar/">Auto Zastępcze</a></li>
            <li className="mr-1 pr-1 position-relative"><a href="/blog/">Blog</a></li>
            <li className="mr-1 pr-1 position-relative"><a href="/contact/">Kontakt</a></li>
            <li className="mr-1 pr-1 position-relative"><a href="/">Polityka prywatności</a></li>
        </ul>
    </div>
</div>
  }else{
    footer = <Footer></Footer>
  }


  return (
    <Main>
      <GlobalStyle />
      <Favicon />

      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap" 
          rel="stylesheet"
        />
        <title>Autopomocni</title>
        <meta property="og:title" content="Autopomocni" />
        <meta property="og:type" content="website" />
  
      </Helmet>

      <StyledHeader className="w-100">
        <Nav />
      </StyledHeader>
      <QuickPhone></QuickPhone>
      {children}
      <div className="main-wrapper">
      {footer}
      </div>
    </Main>
  );
};

export default Layout;
