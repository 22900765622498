import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

class Newsletter extends React.Component {
    state = {
        FNAME: null,
        email: null,
    }
    _handleChange = e => {
        this.setState({
          [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(this.state.email, {FNAME: this.state.FNAME})
        .then(({msg, result}) => {
          console.log('msg', `${result}: ${msg}`);
          if (result !== 'success') {
            throw msg;
          }
          alert(msg);
        })
        .catch(err => {
          console.log('err', err);
          alert(err);
        });
      }
    render(){
        return (
            <div className="newsletter-wrapper w-100">
                <div className="d-flex xs-justify-content-center">
                    <h2 className="green-color mb-2">
                        Bądź na biezaco z pomocnymi artykułami
                    </h2>
                </div>
                <form className="form-style" onSubmit={this._handleSubmit}>
                    <div className="d-flex flex-row justify-content-between">
                    <input required className="c-input mr-1 xs-mr-0 mb-xs-2" type="text" onChange={this._handleChange} name="FNAME" placeholder="Imie"/>
                    <input required className="c-input ml-1 xs-ml-0" type="email" onChange={this._handleChange} name="email" placeholder="mail"/>
                    </div>
                    <button type="submit" className="mt-4 w-100 btn btn-rounded green-bg main-color">Subskrybuj</button>
                </form>
            </div>
        );
    }

}
export default Newsletter;